import React from "react";
import { Box, Button, Typography, Card, Container } from "@mui/material";
import { Link } from "react-router-dom";

const CustomButton = ({ to, label }) => (
	<Button
		sx={{
			backgroundColor: "primary.button",
			color: "white",
			"&:hover": { backgroundColor: "primary.buttonhover" },
			width: "100%",
		}}
	>
		<Link to={to}>{label}</Link>
	</Button>
);

const Home = () => (
	<Box>
		<Container className="mt-20 w-auto">
			<Typography
				sx={{
					fontFamily: "Lora",
					fontWeight: "600",
					marginTop: "54px",
					marginBottom: "54px",
					fontSize: { sm: "45px", xs: "35px", md: "60px", lg: "80px" },
					textAlign: "center",
				}}
			>
				Marsutex S.A
			</Typography>
			<div className="text-center mb-12">
				<Typography variant="h5">
					Explora nuestras marcas
					<br />
					<Typography
						variant="h3"
						sx={{
							fontWeight: 600,
							fontSize: { sm: "25px", xs: "25px", md: "50px", lg: "60px" },
							textAlign: "center",
						}}
					>
						Arnet y Forbro
					</Typography>
				</Typography>
			</div>
			<Container
				sx={{
					display: "flex",
					justifyContent: "center",
					gap: "10px",
					flexDirection: { xs: "column", sm: "column", lg: "row", md: "row" },
				}}
			>
				<Card
					className="flex flex-col justify-end gap-6 shadow-lg p-10 items-center"
					sx={{
						backgroundColor: "primary.main",
						maxWidth: { lg: "450px", xs: "200px", sm: "300px" },
					}}
				>
					<img src="/arnet-logo.png" alt="logo" style={{ width: "100%" }} />
					<Container
						sx={{
							display: "flex",
							flexDirection: {
								xs: "column",
								sm: "row",
								lg: "row",
								md: "row",
							},
							justifyContent: "center",
							gap: "5px",
						}}
					>
						<CustomButton to="/arnet/brasier" label="Brasieres" />
						<CustomButton to="/arnet/panties" label="Panties" />
					</Container>
				</Card>
				<Card
					className="flex flex-col justify-end gap-6 shadow-md p-10 items-center"
					sx={{
						backgroundColor: "primary.main",
						maxWidth: { lg: "450px", xs: "200px", sm: "300px" },
					}}
				>
					<img src="/forbro-logo.png" alt="logo" style={{ width: "100%" }} />
					<Container
						sx={{
							display: "flex",
							flexDirection: {
								xs: "column",
								sm: "column",
								lg: "row",
								md: "row",
							},
							justifyContent: "center",
							gap: "5px",
						}}
					>
						<CustomButton to="/forbro/boxers" label="Boxers" />
						<CustomButton to="/forbro/men" label="Medias Hombre" />
						<CustomButton to="/forbro/baby" label="Medias Bebe" />
					</Container>
				</Card>
			</Container>
		</Container>
	</Box>
);

export default Home;
