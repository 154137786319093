import { useEffect } from "react";
import { Box, Button, Container } from "@mui/material";

export const ProductModal = ({ children, handleClose }) => {
	useEffect(() => {
		// Add event listener to the backdrop element
		const handleClickOutside = (event) => {
			if (event.target === event.currentTarget) {
				handleClose(); // Close the modal when clicking outside of it
			}
		};

		// Attach the event listener
		document.addEventListener("click", handleClickOutside);

		// Cleanup the event listener when the component unmounts
		return () => {
			document.removeEventListener("click", handleClickOutside);
		};
	}, [handleClose]);
	return (
		<Box
			sx={{
				position: "fixed",
				top: 0,
				left: 0,
				width: "100%",
				height: "100%",
				backgroundColor: "rgba(0,0,0,0.5)",
			}}
		>
			<Container
				onClick={(e) => e.stopPropagation()}
				sx={{
					display: "flex",
					gap: "24px",
					width: { xs: "15rem", sm: "18rem", md: "22rem", lg: "25rem" },
					padding: "3rem",
					margin: "100px auto",
					backgroundColor: "#fff",
					borderRadius: "10px",
					flexDirection: "column",
				}}
			>
				{children}
				<Button onClick={handleClose}>Cerrar</Button>
			</Container>
		</Box>
	);
};
