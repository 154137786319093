import { useState } from "react";
import { Card } from "@mui/material";
import { Cloudinary } from "@cloudinary/url-gen";
import {
	AdvancedImage,
	lazyload,
	responsive,
	placeholder,
} from "@cloudinary/react";
import { fill } from "@cloudinary/url-gen/actions/resize";
import { ProductModal } from "./ProductModal";

export const ProductCard = ({ imageUrl, index }) => {
	const [clickedImage, setClickedImage] = useState();
	const [isModalOpen, setIsModalOpen] = useState(false);

	const cld = new Cloudinary({
		cloud: {
			cloudName: "ddundkemp",
		},
	});

	const myImage = cld.image(imageUrl).resize(fill());

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handleClick = () => {
		setClickedImage(cld.image(imageUrl).resize(fill()));
		setIsModalOpen(true);
	};

	return (
		<>
			<Card
				sx={{ width: "100%", maxWidth: "25rem", cursor: "pointer" }}
				onClick={handleClick}
			>
				<AdvancedImage
					cldImg={myImage}
					plugins={[lazyload(), responsive(), placeholder()]}
				/>
			</Card>
			{isModalOpen && (
				<ProductModal handleClose={handleClose}>
					<AdvancedImage
						cldImg={clickedImage}
						plugins={[lazyload(), responsive(), placeholder()]}
					/>
				</ProductModal>
			)}
		</>
	);
};
