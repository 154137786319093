import React from "react";
import { Routes, Route } from "react-router-dom";
import { Box, Container } from "@mui/material";
import ProductGrid from "./containers/app/ProductGrid";
import SidebarGlobal from "./containers/global/SidebarGlobal";
import Home from "./containers/app/Home";

function App() {
	return (
		<Box className="w-auto h-full flex flex-row">
			<SidebarGlobal />
			<Container
				sx={{
					display: "flex",
					marginLeft: { xs: "5px", lg: "20px" },
					justifyContent: "center",
				}}
			>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/:category/:subcategory" element={<ProductGrid />} />
				</Routes>
			</Container>
		</Box>
	);
}

export default App;
