/* eslint-disable no-unused-vars */
import { useState, useEffect } from "react";
import {
	Sidebar,
	Menu,
	MenuItem,
	SubMenu,
	sidebarClasses,
} from "react-pro-sidebar";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Card, Typography } from "@mui/material";

function SidebarGlobal() {
	const isMobile = useMediaQuery("(max-width:1050px)");
	const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile); // Initially open on larger screens
	const navigate = useNavigate();

	useEffect(() => {
		if (!isMobile) {
			setIsSidebarOpen(true);
		} else {
			setIsSidebarOpen(false);
		}
	}, [isMobile]); // Empty dependency array ensures this effect runs only once after the initial render

	const toggleSidebar = () => {
		setIsSidebarOpen(!isSidebarOpen);
	};

	return (
		<Sidebar
			collapsed={!isSidebarOpen}
			onCollapse={toggleSidebar}
			rootStyles={{
				[`.${sidebarClasses.container}`]: {
					backgroundColor: "rgba(0, 0, 0, 0.3)",
					display: "flex",
					flexDirection: "column", // Set the container to flex column
					minHeight: "100vh",
					minWidth: "5rem",
				},
			}}
		>
			{isMobile && (
				<div>
					<IconButton onClick={toggleSidebar}>
						{isSidebarOpen ? (
							<CloseIcon sx={{ fontSize: "2rem" }} />
						) : (
							<MenuIcon sx={{ fontSize: "2rem" }} />
						)}
					</IconButton>
				</div>
			)}
			{isSidebarOpen && (
				<>
					<div
						style={{ marginBottom: "24px", cursor: "pointer" }}
						onClick={() => navigate("/")}
					>
						<h1
							className="text-center mt-5 font-bold"
							style={{ fontFamily: "Lora, serif", fontSize: "35px" }}
						>
							Marsutex S.A.
						</h1>
					</div>
					<Menu>
						<div>
							<SubMenu
								label={
									<div>
										<img
											src="/forbro-logo.png"
											style={{ width: "200px" }}
											alt="LOGO"
										></img>
									</div>
								}
								style={{ height: "250px" }}
							>
								<MenuItem component={<Link to="/forbro/boxers" />}>
									Boxers
								</MenuItem>
								<MenuItem component={<Link to="/forbro/men" />}>
									Medias Hombre
								</MenuItem>
								<MenuItem component={<Link to="/forbro/baby" />}>
									Medias Bebe
								</MenuItem>
							</SubMenu>
						</div>
						<div style={{ marginBottom: "20px" }}>
							<SubMenu
								label={
									<div>
										<img
											src="/arnet-logo.png"
											style={{ width: "200px" }}
											alt="LOGO"
										></img>
									</div>
								}
								style={{ height: "250px" }}
							>
								<MenuItem component={<Link to="/arnet/brasier" />}>
									Brasieres
								</MenuItem>
								<MenuItem component={<Link to="/arnet/panties" />}>
									Panties
								</MenuItem>
							</SubMenu>
						</div>
					</Menu>
					<div className="text-center text-white">
						<Typography variant="h4">Contactanos</Typography>
						<br />
						<div className="mt-0">
							<a
								href="mailto:ventas@marsutex.com"
								sx={{
									fontWeight: 600,
									marginBottom: "2px",
									backgroundColor: "white",
								}}
							>
								<Typography variant="subtitle">ventas@marsutex.com</Typography>
							</a>
							<br />
							<a
								href="tel:+5072036311"
								sx={{
									fontWeight: 600,
									backgroundColor: "white",
									padding: "1rem",
								}}
							>
								<Typography variant="subtitle">+507 203-6311</Typography>
							</a>
						</div>
					</div>
				</>
			)}
		</Sidebar>
	);
}

export default SidebarGlobal;
