import { createTheme } from "@mui/material/styles";

const theme = createTheme({
	breakpoints: {
		values: {
			xs: 0, // Extra small devices (phones)
			sm: 600, // Small devices (tablets)
			md: 960, // Medium devices (desktops)
			lg: 1280, // Large devices (large desktops)
		},
	},
	palette: {
		primary: {
			main: "rgba(0, 0, 0, 0.3)",
			button: "rgba(0, 0, 0,0.05)",
			buttonhover:"rgba(0, 0, 0,0.5)"
		},
	},
	typography: {
		fontFamily: "Lora, sans-serif",
		h1: {
			fontFamily: "Lora",
			fontWeight: 600,
			fontSize: "80px",
			// Responsive font size using breakpoints
			[`@media (max-width:${960}px)`]: {
				fontSize: "60px", // Adjust font size for medium-sized screens (e.g., tablets)
			},
			[`@media (max-width:${600}px)`]: {
				fontSize: "40px", // Adjust font size for small screens (e.g., phones)
			},
		},
		// Define font styles for other typography elements as needed
	},
});

export default theme;
