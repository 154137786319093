import React, { useState, useEffect } from "react";
import { ProductCard } from "../../components/ProductCard";
import { Box, Container, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import axios from "axios";

const ProductGrid = () => {
	const { category, subcategory } = useParams();
	const [images, setImages] = useState([]);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		async function fetchData() {
			setImages([]);
			setLoading(true);
			try {
				const response = await axios.get(
					`https://masrutex-back-0e5da3c13da1.herokuapp.com/images/${category}/${subcategory}`
				);

				if (response.status !== 200) {
					throw new Error(`HTTP error! Status: ${response.status}`);
				}

				const data = response.data;
				setImages(data.imageNames);
				setLoading(false);
			} catch (error) {
				console.error("Error fetching images:", error);
				setLoading(false);
			}
		}

		fetchData();
	}, [category, subcategory]);

	return (
		<Box className="w-full">
			<Container className="mt-20 w-auto">
				<h1 className="text-center mb-10 text-2xl font-bold">
					CATALOGO {subcategory.toUpperCase()}
				</h1>
				<div className="grid xs:grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
					{images.map((imageName, index) => (
						<ProductCard key={index} imageUrl={imageName} />
					))}
				</div>
			</Container>
			{loading && <CircularProgress />}
		</Box>
	);
};

export default ProductGrid;
